import React from "react";
import initialProjectData_de from '../data/initialProjectData.json';
import initialProjectData_en from '../data/initialProjectData_en.json';
import initialProjectData_es from '../data/initialProjectData_es.json';
import initialProjectData_nl from '../data/initialProjectData_nl.json';
import initialProjectData_fr from '../data/initialProjectData_fr.json';
import initialProjectData_it from '../data/initialProjectData_it.json';
import knx_equipmentData_step2_de from '../data/knx_ausstattungen_step2.json';
import knx_equipmentData_step2_en from '../data/knx_ausstattungen_step2_en.json';
import knx_equipmentData_step2_es from '../data/knx_ausstattungen_step2_es.json';
import knx_equipmentData_step2_nl from '../data/knx_ausstattungen_step2_nl.json';
import knx_equipmentData_step2_fr from '../data/knx_ausstattungen_step2_fr.json';
import knx_equipmentData_step2_it from '../data/knx_ausstattungen_step2_it.json';
import knx_equipmentData_step4_de from '../data/knx_ausstattungen_step4.json';
import knx_equipmentData_step4_en from '../data/knx_ausstattungen_step4_en.json';
import knx_equipmentData_step4_es from '../data/knx_ausstattungen_step4_es.json';
import knx_equipmentData_step4_nl from '../data/knx_ausstattungen_step4_nl.json';
import knx_equipmentData_step4_fr from '../data/knx_ausstattungen_step4_fr.json';
import knx_equipmentData_step4_it from '../data/knx_ausstattungen_step4_it.json';
import konventionell_equipmentData_step2_de from '../data/konventionell_ausstattungen_step2.json';
import konventionell_equipmentData_step2_en from '../data/konventionell_ausstattungen_step2_en.json';
import konventionell_equipmentData_step2_es from '../data/konventionell_ausstattungen_step2_es.json';
import konventionell_equipmentData_step2_nl from '../data/konventionell_ausstattungen_step2_nl.json';
import konventionell_equipmentData_step2_fr from '../data/konventionell_ausstattungen_step2_fr.json';
import konventionell_equipmentData_step2_it from '../data/konventionell_ausstattungen_step2_it.json';
import konventionell_equipmentData_step4_de from '../data/konventionell_ausstattungen_step4.json';
import konventionell_equipmentData_step4_en from '../data/konventionell_ausstattungen_step4_en.json';
import konventionell_equipmentData_step4_es from '../data/konventionell_ausstattungen_step4_es.json';
import konventionell_equipmentData_step4_nl from '../data/konventionell_ausstattungen_step4_nl.json';
import konventionell_equipmentData_step4_fr from '../data/konventionell_ausstattungen_step4_fr.json';
import konventionell_equipmentData_step4_it from '../data/konventionell_ausstattungen_step4_it.json';
import home_equipmentData_step2_de from '../data/home_ausstattungen_step2.json';
import home_equipmentData_step2_en from '../data/home_ausstattungen_step2_en.json';
import home_equipmentData_step2_es from '../data/home_ausstattungen_step2_es.json';
import home_equipmentData_step2_nl from '../data/home_ausstattungen_step2_nl.json';
import home_equipmentData_step2_fr from '../data/home_ausstattungen_step2_fr.json';
import home_equipmentData_step2_it from '../data/home_ausstattungen_step2_it.json';
import home_equipmentData_step4_de from '../data/home_ausstattungen_step4.json';
import home_equipmentData_step4_en from '../data/home_ausstattungen_step4_en.json';
import home_equipmentData_step4_es from '../data/home_ausstattungen_step4_es.json';
import home_equipmentData_step4_nl from '../data/home_ausstattungen_step4_nl.json';
import home_equipmentData_step4_fr from '../data/home_ausstattungen_step4_fr.json';
import home_equipmentData_step4_it from '../data/home_ausstattungen_step4_it.json';
import StepOneComponent from "./StepOneComponent";
import StepTwoComponent from "./StepTwoComponent";
import logoSvg from "./../images/logo.svg"
import logoSvgLight from "./../images/logo_light.svg"
import StepThreeComponent from "./StepThreeComponent";
import StepThree1Component from "./StepThree1Component";
import StepFourComponent from "./StepFourComponent";
import StepFiveComponent from "./StepFiveComponent";
import {GenerateRoomToAddService} from "../services/GenerateRoomToAddService";
import Button from '@material-ui/core/Button';
import {createTheme, MuiThemeProvider, StepIcon, withStyles} from "@material-ui/core";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import StepHomeComponent from "./StepHomeComponent";
import HomeSubPagesComponent from "./HomeSubpagesComponent";
import uarr from "../images/arrow_up.svg";
import uarrh from "../images/arrow_up_hover.svg";
import {v4 as uuidv4} from "uuid";
import roomList from "../data/availableRooms.json";
import {ProjectSaveService} from "../services/ProjectSaveService";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import FilterIcon2 from "@material-ui/icons/Tune";
import iIconActive from "../images/info_icon_active.svg";
import iIconBlue from "../images/info_icon_blue.svg";
import Popover from "@material-ui/core/Popover";
import documentTitle from "../services/documentTitle";
import {useTranslation} from "react-i18next";
import StepVerifyComponent from "./StepVerifyComponent";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function ProjectComponent({
     isExpert = false, project, setProject, setSelectedProject, initProject, generateProject,
     currentStep, setCurrentStep, projectSelectList, getSchalterDesign, uiState, setUiState, getInitialUiState,
     startWithProject, isIFrame, trackingObj, isKonventionell, isHome, setTrackingId, isMyJung = false,
     jumpTo, offsetTopPos, scrollTop, setPrCode, prCode, lastSum, setLastSum, isDebug, isOldUrl
}){

    const [resetDialogOpen, setResetDialogOpen] = React.useState(false);
    // const [loadProjectOpen, setLoadProjectOpen] = React.useState(false);
    const [projectCode, setProjectCode] = React.useState('');
    const [showNav, setShowNav] = React.useState(false);
    const {t, i18n} = useTranslation();
    const language = i18n.resolvedLanguage;

    const [showFoerderung, setShowFoerderung] = React.useState(false);
    const raumControllerEnabled = ['knx'].includes(project.projectType);
    const [sentCodes, setSentCodes] = React.useState([]);

    if (project.projectType === 'konventionell') { documentTitle(t('app.browser.title.' + project.projectType)); }
    else if (project.projectType === 'knx') { documentTitle(t('app.browser.title.' + project.projectType)); }
    else if (project.projectType === 'home') { documentTitle(t('app.browser.title.' + project.projectType)); }

    if (!isIFrame) { document.body.overflowY = 'auto'; }
    // console.log('projectcomponent starting with project', project)
    // console.log('isOldUrl', isOldUrl)
    function navigateToStep(value){
        setCurrentStep(value)
        if ('parentIFrame' in window) {
            // window.parentIFrame.scrollToOffset(0, 0);
            window.parentIFrame.scrollTo(0, offsetTopPos - 64);
        } else {
            window.scrollTo(0, 0);
        }
        if (trackingObj) {
            ProjectSaveService.saveTracking({step: value, trackingId: trackingObj.trackingId}, undefined, isOldUrl, language, trackingObj)
        }
    }
    // function navigateToBottom() {
    //     window.scrollTo(0, window.document.body.scrollHeight);
    // }

    function updateProject(projectIn){
        // const wtop = window.document.documentElement.scrollTop;
        setProject({...projectIn});//, true);
        // if (window.parentIFrame === undefined) {
        //     setTimeout(() => {
        //         document.body.style.overflowY = 'auto';
        //         window.document.body.style.overflow = 'auto';
        //         window.document.documentElement.scrollTo(0, wtop);
        //     }, 100);
        // }
    }

    function getEuqipmentDataStep2(projectType){
        switch (projectType) {
            case "knx":
                if (language === 'en') return filterEquipmentForSelectecBuildingType(knx_equipmentData_step2_en);
                else if (language === 'es') return filterEquipmentForSelectecBuildingType(knx_equipmentData_step2_es);
                else if (language === 'nl') return filterEquipmentForSelectecBuildingType(knx_equipmentData_step2_nl);
                else if (language === 'fr') return filterEquipmentForSelectecBuildingType(knx_equipmentData_step2_fr);
                else if (language === 'it') return filterEquipmentForSelectecBuildingType(knx_equipmentData_step2_it);
                return filterEquipmentForSelectecBuildingType(knx_equipmentData_step2_de);
            case "konventionell":
                if (language === 'en') return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step2_en);
                else if (language === 'es') return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step2_es);
                else if (language === 'nl') return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step2_nl);
                else if (language === 'fr') return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step2_fr);
                else if (language === 'it') return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step2_it);
                return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step2_de);
            case "home":
                if (language === 'en') return filterEquipmentForSelectecBuildingType(home_equipmentData_step2_en);
                else if (language === 'es') return filterEquipmentForSelectecBuildingType(home_equipmentData_step2_es);
                else if (language === 'nl') return filterEquipmentForSelectecBuildingType(home_equipmentData_step2_nl);
                else if (language === 'fr') return filterEquipmentForSelectecBuildingType(home_equipmentData_step2_fr);
                else if (language === 'it') return filterEquipmentForSelectecBuildingType(home_equipmentData_step2_it);
                return filterEquipmentForSelectecBuildingType(home_equipmentData_step2_de);
            default:
                return [];
        }
    }
    function getEuqipmentDataStep4(projectType){
        switch (projectType) {
            case "knx":
                if (language === 'en') return filterEquipmentForSelectecBuildingType(knx_equipmentData_step4_en);
                else if (language === 'es') return filterEquipmentForSelectecBuildingType(knx_equipmentData_step4_es);
                else if (language === 'nl') return filterEquipmentForSelectecBuildingType(knx_equipmentData_step4_nl);
                else if (language === 'fr') return filterEquipmentForSelectecBuildingType(knx_equipmentData_step4_fr);
                else if (language === 'it') return filterEquipmentForSelectecBuildingType(knx_equipmentData_step4_it);
                return filterEquipmentForSelectecBuildingType(knx_equipmentData_step4_de);
            case "konventionell":
                if (language === 'en') return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step4_en);
                else if (language === 'es') return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step4_es);
                else if (language === 'nl') return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step4_nl);
                else if (language === 'fr') return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step4_fr);
                else if (language === 'it') return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step4_it);
                return filterEquipmentForSelectecBuildingType(konventionell_equipmentData_step4_de);
            case "home":
                if (language === 'en') return filterEquipmentForSelectecBuildingType(home_equipmentData_step4_en);
                else if (language === 'es') return filterEquipmentForSelectecBuildingType(home_equipmentData_step4_es);
                else if (language === 'nl') return filterEquipmentForSelectecBuildingType(home_equipmentData_step4_nl);
                else if (language === 'fr') return filterEquipmentForSelectecBuildingType(home_equipmentData_step4_fr);
                else if (language === 'it') return filterEquipmentForSelectecBuildingType(home_equipmentData_step4_it);
                return filterEquipmentForSelectecBuildingType(home_equipmentData_step4_de);
            default:
                return [];
        }
    }
    function filterEquipmentForSelectecBuildingType(listIn) {
        if (language === 'en') return listIn.filter(item => (item.availableForBuildingType === undefined || item.availableForBuildingType.some(l => (initialProjectData_en[project.selectedMusterIx].type === l))));
        else if (language === 'es') return listIn.filter(item => (item.availableForBuildingType === undefined || item.availableForBuildingType.some(l => (initialProjectData_es[project.selectedMusterIx].type === l))));
        else if (language === 'nl') return listIn.filter(item => (item.availableForBuildingType === undefined || item.availableForBuildingType.some(l => (initialProjectData_nl[project.selectedMusterIx].type === l))));
        else if (language === 'fr') return listIn.filter(item => (item.availableForBuildingType === undefined || item.availableForBuildingType.some(l => (initialProjectData_fr[project.selectedMusterIx].type === l))));
        else if (language === 'it') return listIn.filter(item => (item.availableForBuildingType === undefined || item.availableForBuildingType.some(l => (initialProjectData_it[project.selectedMusterIx].type === l))));
        return listIn.filter(item => (item.availableForBuildingType === undefined || item.availableForBuildingType.some(l => (initialProjectData_de[project.selectedMusterIx].type === l))));
    }

    function renderSwitch(param) {
        if (raumControllerEnabled) {
            if (param === 5) { param = 4; }
            if (param === 6) { param = 5; }
        }
        if (isDebug) { param = 55; }
        switch(param) {
            case 2:
                return <StepTwoComponent
                    project={project}
                    equipmentData={getEuqipmentDataStep2(project.projectType)}
                    updateProject={updateProject}
                    updateProjectWithNewLevel={p => updateProject(generateProject(p, undefined, '', true))}
                    trackingObj={trackingObj}
                    jumpTo={jumpTo}
                    isIFrame={isIFrame}
                />;
            case 3:
                return <StepThreeComponent
                    project={project}
                    updateProject={updateProject}
                    uiState={uiState}
                    setUiState={setUiState}
                    trackingObj={trackingObj}
                    jumpTo={jumpTo}
                    isIFrame={isIFrame}
                />;
            case 3.1:
                return <StepThree1Component
                    project={project}
                    updateProject={updateProject}
                    uiState={uiState}
                    setUiState={setUiState}
                    trackingObj={trackingObj}
                    jumpTo={jumpTo}
                    isIFrame={isIFrame}
                />;
            case 4:
                return <StepFourComponent
                    project={project}
                    equipmentData={getEuqipmentDataStep4(project.projectType)}
                    updateProject={updateProject}
                    updateProjectWithNewLevel={p => updateProject(generateProject(p))}
                    trackingObj={trackingObj}
                    jumpTo={jumpTo}
                    isIFrame={isIFrame}
                />;
            case 5:
                return <StepFiveComponent
                    project={project}
                    trackingObj={trackingObj}
                    equipmentData2={getEuqipmentDataStep2(project.projectType)}
                    equipmentData4={getEuqipmentDataStep4(project.projectType)}
                    isKonventionell={isKonventionell}
                    isExpert={isExpert}
                    isHome={isHome}
                    isMyJung={isMyJung}
                    jumpTo={jumpTo}
                    isIFrame={isIFrame}
                    navigateToStep={navigateToStep}
                    handleResetDialogOpen={handleResetDialogOpen}
                    navigateToTop={navigateToTop}
                    setPrCode={setPrCode}
                    prCode={prCode}
                    lastSum={lastSum}
                    setLastSum={setLastSum}
                    isOldUrl={isOldUrl}
                    sentCodes={sentCodes}
                    setSentCodes={setSentCodes}
                />;
            case 1:
                return <StepOneComponent
                    project={project}
                    projectSelectList={projectSelectList}
                    setSelectedProject={setSelectedProject}
                    updateProject={updateProject}
                    startProject={startProject}
                    schalterdesign={getSchalterDesign(project.projectType)}
                    setProjectCode={setProjectCode}
                    projectCode={projectCode}
                    loadProjectFromCode={loadProjectFromCode}
                    trackingObj={trackingObj}
                    isIFrame={isIFrame}
                    jumpTo={jumpTo}
                    setPrCode={setPrCode}
                    initproject={initProject}
                />;
            case 55:
                return <StepVerifyComponent />;
                break;
            default:
                return <StepHomeComponent project={project}
                                          startProject={startProject} />
        }
    }
    function renderStartSubPages() {
        return <HomeSubPagesComponent project={project}
                                      startProject={startProject} />
    }
    function getDefaultEquipmentForSelectedLevel(type, selectedLevel){
        const list1 = roomList.filter(rl => (rl.type === type));
        const list2 = list1.length > 0 ? (list1[0]).defaultEquipment.filter(se => (se.level === selectedLevel)) : [];
        return list2.length > 0 ? list2[0].values : {};
    }
    function startProject(projectType, subProjectType = "neubau", resetDesign = false, navigateToOne = true) {
        GenerateRoomToAddService.resetDefaultDesignConfig();
        const schalterdesign = getSchalterDesign(projectType || project.projectType || "knx");
        const selectedLevel = typeof project.selectedLevel != "undefined" ? project.selectedLevel : 1;
        const initedProject = initProject(projectType, subProjectType);
        const floors = project.floors.length ? project.floors : initedProject.floors;
        const floorToUse = floors.map(i => {
                return {
                    ...i,
                    id: i.id ? i.id : uuidv4(),
                    rooms: i.rooms.map(r => {
                        if (projectType) {
                            r = {...r, selectedDesign: undefined};
                        }
                        let equipment = getDefaultEquipmentForSelectedLevel(r.type, selectedLevel);
                        if (subProjectType === "umbau") {
                            const skipEquipment = ['telefon_datenanschluss', 'radio_datenanschluss_sat', 'telefon_datenanschluss', 'telefonanschlusss', 'radio_datenanschluss_kabel'];
                            Object.keys(equipment).forEach(key => {
                                equipment = {...equipment, [key]: skipEquipment.includes(key) ? equipment[key] : 0};
                            });
                            r = {...r, doorCount: 0, windowCount: 0, heatingCount: 0, floorheatingCount: 0};
                        } else if (r.originalValues) {
                            if (r.doorCount === 0 && r.windowCount === 0 && r.floorheatingCount === 0 && r.heatingCount === 0) {
                                r = {
                                    ...r,
                                    doorCount: r.originalValues.doorCount,
                                    windowCount: r.originalValues.windowCount,
                                    floorheatingCount: r.originalValues.floorheatingCount,
                                    heatingCount: r.originalValues.heatingCount
                                };
                            }
                        }
                        return {
                            ...r,
                            id: r.id ? r.id : uuidv4(),
                            selectedDesign: resetDesign
                                ? (r.selectedDesign ? r.selectedDesign : GenerateRoomToAddService.getDefaultDesignConfig(schalterdesign))
                                : GenerateRoomToAddService.getDefaultDesignConfig(schalterdesign),
                            selectedEquipment: equipment
                        }
                    })
                };
            }
        );
        let newProject = {...initedProject, projectName: project.projectName, floors: floorToUse};
        // if (subProjectType === "umbau") { newProject = {...newProject, floors: []}; }
        updateProject(newProject);
        setUiState(getInitialUiState(projectType));
        if (navigateToOne){
            navigateToStep(1);
        }
    }

    const ColorButton1 = withStyles((theme) => ({
        root: {
            color: "var(--weiss)",
            backgroundColor: "var(--grau3)",
            '&:hover': {
                backgroundColor:"var(--grau3)",
            },
            borderRadius: "8px",
            width: "143px",
            height: "45px",
            lineHeight: "45px",
            fontSize: "16px",
            textAlign: "center",
            margin: "20px auto",
            textTransform: "inherit"
        },
    }))(Button);
    const ColorButton2 = withStyles((theme) => ({
        root: {
            color: "var(--weiss)",
            backgroundColor: "var(--color1)",
            '&:hover': {
                backgroundColor:"var(--color1)",
            },
            borderRadius: 0,
            width: "143px",
            height: "45px",
            lineHeight: "45px",
            fontSize: "16px",
            textAlign: "center",
            margin: "20px",
            textTransform: "inherit"
        },
    }))(Button);
    const ColorButtonToTop = withStyles((theme) => ({
        root: {
            color: "var(--color2)",
            backgroundColor: "transparent",
            cursor: "pointer",
            borderRadius: 0,
            width: "auto",
            height: "45px",
            lineHeight: "45px",
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
            margin: "-40px -10px 0 auto",
            textTransform: "inherit",
            border: "3px solid transparent",
            '&:hover': {
                backgroundColor: "transparent",
                color: "var(--color1)",
            },
            float: "right",
        },
    }))(Button);
    const ColorButton4 = withStyles((theme) => ({
        root: {
            color: "var(--weiss)",
            backgroundColor: "var(--darkblue)",
            '&:hover': {
                backgroundColor:"var(--darkblue)",
            },
            cursor: "pointer",
            height: "45px",
            padding: "45px 15px",
            fontSize: "16px",
            marginTop: "0",
            textAlign: "left",
            lineHeight: "1.3",
            borderRadius: "0",
            textTransform: "inherit",
            width: "32%",
            marginRight: "1%",
        },
    }))(Button);
    const HeaderButton = withStyles((theme) => ({
        root: {
            color: "var(--textColor)",
            backgroundColor: "var(--weiss)",
            '&:hover': {
                backgroundColor:"var(--blueColor)",
                color: "var(--weiss)"
            },
            '&.active': {
                backgroundColor:"var(--blueColor)",
                color: "var(--weiss)"
            },
            cursor: "pointer",
            height: "63px",
            padding: "21px 30px",
            fontSize: "22px",
            margin: "0",
            textAlign: "center",
            borderRadius: "0",
            textTransform: "inherit",
            width: "auto",
        },
    }))(Button);
    function loadProjectFromCode(){
        if(projectCode){
            // setLoadProjectOpen(false);
            ProjectSaveService.loadProject(projectCode, startWithProject, language, project.isMyJung);
            setProjectCode(null);
        }
    }

    const theme = createTheme({
        overrides: {
            MuiStepper:{
                root:{
                    padding: "10px",
                    background: "transparent",
                    marginTop: "20px",
                }
            },
            MuiStepIcon: {
                root:{
                    color: 'var(--grau3)',
                    "&$active": {
                        color: 'var(--color1)',
                    },
                    "&$completed": {
                        color: 'var(--color1)',
                    }
                }
            },
        },
    });

    function navigateToTop() {
        if ('parentIFrame' in window) {
            window.parentIFrame.scrollToOffset(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
    }
    function handleResetDialogOpen(e) {
        setResetDialogOpen(true);
        setShowNav(!showNav);
        if (e) { e.preventDefault(); e.stopPropagation(); }
        // if ('parentIFrame' in window) {
        //     const popups = document.getElementsByClassName('MuiDialog-paperScrollPaper');
        //     for(let i=0; i < popups.length; i++) {
        //         let currentPopup = popups[i];
        //         currentPopup.style.marginTop = (scrollTop - offsetTopPos) + 'px';
        //     }
        // }
        return false;
    }
    function handleResetDialogClose(){
        setResetDialogOpen(false);
    }
    function handleResetDialogAgree(e){
        setTrackingId(null);
        ProjectSaveService.saveTracking({trackingId: null, step: 1, b2b: isMyJung}, setTrackingId, isOldUrl, language);
        setProject(initProject());
        setCurrentStep(1);
        setUiState(getInitialUiState());
        setResetDialogOpen(false);
        if ('parentIFrame' in window) {
            window.parentIFrame.scrollToOffset(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
    }
    // const HtmlTooltipDesign = withStyles((theme) => ({
    //     tooltip: {
    //         backgroundColor: "var(--weiss)",
    //         color: "var(--textColor)",
    //         fontSize: "14px",
    //         border: '1px solid #dadde9',
    //         maxWidth: '400px',
    //         minWidth: '150px',
    //         padding: "0px 7px",
    //         boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    //     },
    // }))(Tooltip);
    // const InfoOutlinedIconCustomActive = withStyles((theme) => ({
    //     root: {
    //         color: "var(--captionColor)",
    //         fontSize: "18px",
    //         verticalAlign: "sub",
    //         alignContent: "right",
    //         paddingLeft: "5px"
    //     },
    // }))(InfoOutlinedIcon);
    function GetMenuIcon(prop) {
        if (showNav) { return <CloseIcon />; }
        return <MenuIcon />;
    }
    function CustomIcon(step) {
        return <div style={{width: '28px', height: '28px'}} className={"step-button" + (step.active ? " active" : "") + (step.completed ? " completed" : "")}>
            <div className={"label"}>{step.value}</div>
            <div className={"step-tooltip"} dangerouslySetInnerHTML={{ __html: step.txt }}></div>
        </div>;
    }
    function TooltipWithButton(props) {
        const el = document.createElement('a');
        document.body.appendChild(el);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [opened, setOpened] = React.useState(false);
        const id = 'popover_' + ((Math.random() * 1000) + 1);
        // const open = Boolean(anchorEl);
        const params = props.props;
        // setTimeout(() => {if (!opened) {document.body.style.overflow = 'unset';}}, 100);
        const handleClick = (event) => {
            event.preventDefault(); event.stopPropagation();
            setAnchorEl(event.currentTarget); setOpened(true);
            // document.body.style.overflow = 'hidden';
            return false;
        };
        const handleClose = (event) => {
            event.preventDefault(); event.stopPropagation();
            setOpened(false);
            // document.body.style.overflow = 'unset';
            return false;
        };
        return <>
            <Button aria-describedby={id} variant="contained" onClick={handleClick} className={"button-without-style" + (!params.active ? (!params.noActive ? ' inactive' : '') : '') + (params.absolutePosition ? ' absolutePosition' : '')}>
                {params.active
                    ? <span className="info-icon">
                        <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"paramActive"}/>
                    </span>
                    : (!params.noActive
                            ? <span className="info-icon">
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"hideOnHover"}/>
                            <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"showOnHover"}/>
                        </span>
                            : <span>
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "2px"}} alt={"info"} className={"paramNoActive"}/>
                        </span>
                    )
                }
            </Button>
            {'parentIFrame' in window
            ? <Popover id={id} open={true} anchorEl={anchorEl} onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible' : 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>
            : <Popover id={id} open={opened} anchorEl={anchorEl} onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible' : 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>}
        </>;
    }
    if (!isIFrame) { document.body.style.overflow = 'unset'; }
    let contactURL = "https://www.jung-group.com/de-DE/Das-Unternehmen/Kontakt/Kundencenter/";
    if (language == 'nl') { contactURL = "https://www.jung-group.com/nl-NL/Het-bedrijf/Contact/Hateha/"; }
    if (language == 'es') { contactURL = "https://www.jung-group.com/es-ES/La-empresa/Contacto/JUNG-Electro-Iberica/"; }
    if (language == 'at') { contactURL = "https://www.jung-group.com/de-AT/Das-Unternehmen/Kontakt/Euro-Unitech/"; }
    if (currentStep > 0) {
        return <MuiThemeProvider theme={theme}>
            <div className={"header-container" + (isIFrame ? ' hidden' : '')}>
                <div className="header-fixed desktop">
                    <HeaderButton className="active">{t('app.menu.currentProject')}</HeaderButton>
                    <HeaderButton onClick={handleResetDialogOpen}>{t('app.menu.newProject')}</HeaderButton>
                    <div className="logo-container">
                        <img src={logoSvgLight} alt="logo"/>
                    </div>
                    {language == 'de' ? <HeaderButton href={"https://www.mein-elektroinstallateur.de/"} target={"_blank"} rel="noreferrer">{t('app.menu.partners')}</HeaderButton> : null}
                    <HeaderButton href={contactURL} target={"_blank"} rel="noreferrer">{t('app.menu.contact')}</HeaderButton>
                    <Dialog
                        open={resetDialogOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleResetDialogClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description" color={"inherit"}>
                                {t('app.dialog.new.title')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{justifyContent: "center"}}>
                            <div onClick={handleResetDialogClose} color="primary"
                                 className={"modalButton disagree"}>
                                {t('app.dialog.new.button.cancel')}
                            </div>
                            <div onClick={handleResetDialogAgree} color="primary" className={"modalButton agree"}>
                                {t('app.dialog.new.button.create')}
                            </div>
                        </DialogActions>
                    </Dialog>
                </div>
                <div className="header-fixed mobile">
                    <div className="logo-container">
                        <img src={logoSvgLight} alt="logo"/>
                    </div>
                    <div className={"nav-bar"}>
                        <div className={"container"}>
                            <a href={"/"} className='nav-toggler' onClick={(event) => {setShowNav(!showNav); event.preventDefault(); return false;}}>
                                <GetMenuIcon props={{open:showNav}} />
                            </a>
                        </div>
                    </div>
                    <div className={"nav-body" + (showNav ? " visible" : " hidden")}>
                        <div className={"menu-title"}>{t('app.menu.currentProject')}</div>
                        <a href={"/"} onClick={(event) => handleResetDialogOpen(event)}>{t('app.menu.newProject')}</a>
                        <a href={"https://www.mein-elektroinstallateur.de/"} target={"_blank"} rel="noreferrer">{t('app.menu.partners')}</a>
                        <a href={"https://www.jung-group.com/de-DE/Das-Unternehmen/Kontakt/Kundencenter/"} target={"_blank"} rel="noreferrer">{t('app.menu.contact')}</a>
                        <div className={"spacer"}></div>
                        <a href={"https://www.jung-group.com/de-DE/Weitere-Informationen/Impressum/"} target={"_blank"} rel="noreferrer">{t('app.menu.impressum')}</a>
                        <a href={"https://www.jung-group.com/de-DE/Weitere-Informationen/Datenschutzerklaerung/"} target={"_blank"} rel="noreferrer">{t('app.menu.dataProtection')}</a>
                        <a href={"https://feelsmart.com/agb"} target={"_blank"} rel="noreferrer">{t('app.menu.userAgreement')}</a>
                    </div>
                    <Dialog
                        open={resetDialogOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleResetDialogClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description" color={"inherit"}>
                                {t('app.dialog.new.title')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{justifyContent: "center"}}>
                            <div onClick={handleResetDialogClose} color="primary"
                                 className={"modalButton disagree"}>
                                {t('app.dialog.new.button.cancel')}
                            </div>
                            <div onClick={handleResetDialogAgree} color="primary" className={"modalButton agree"}>
                                {t('app.dialog.new.button.create')}
                            </div>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
            <div className="steps-container">
                <div className="steps-fixed">
                    <Stepper activeStep={currentStep - 1} alternativeLabel style={{marginLeft: "auto", marginRight: "auto"}}>
                        <Step key="1"
                              onClick={e => currentStep >= 2 ? navigateToStep(1) : null}
                              className={currentStep >= 2 ? "mouseover-hand" : ""}
                        >
                            <StepIcon icon={<CustomIcon value="1" active={currentStep === 1} completed={currentStep > 1} txt={t('app.steps.one')} />}></StepIcon>
                            {/*<StepLabel>Gebäudestruktur<br/>anlegen</StepLabel>*/}
                        </Step>
                        <Step key="2"
                              onClick={e => currentStep >= 3 || currentStep === 1 ? navigateToStep(2) : null}
                              className={[1, 3, 4, 5].includes(currentStep) ? "mouseover-hand" : ""}
                        >
                            <StepIcon icon={<CustomIcon value="2" active={currentStep === 2} completed={currentStep > 2} txt={t('app.steps.two')} />}></StepIcon>
                        </Step>
                        <Step key="3"
                              onClick={e => currentStep >= 4 || currentStep === 2 || currentStep === 3.1 ? navigateToStep(3) : null}
                              className={[2, 3.1, 4, 5].includes(currentStep) ? "mouseover-hand" : ""}
                        >
                            <StepIcon icon={<CustomIcon value="3" active={currentStep === 3} completed={currentStep > 3}
                                                        txt={project.projectType === 'konventionell' ? t('app.steps.three.konventionell') : t('app.steps.three')} />}></StepIcon>
                        </Step>
                        {raumControllerEnabled ?
                            <Step key="3.1"
                                  onClick={e => currentStep >= 4 || currentStep === 3 ? navigateToStep(3.1) : null}
                                  className={[2, 3, 4, 5].includes(currentStep) ? "mouseover-hand" : ""}
                            >
                                <StepIcon icon={<CustomIcon value="4" active={currentStep === 3.1} completed={currentStep > 3.1}
                                                            txt={t('app.steps.three.1')} />}></StepIcon>
                            </Step>
                        : null }
                        <Step key={raumControllerEnabled ? 5 : 4}
                              onClick={e => currentStep >= 5 || currentStep === (raumControllerEnabled ? 3.1 : 3) ? navigateToStep(raumControllerEnabled ? 5 : 4) : null}
                              className={([raumControllerEnabled ? 3.1 : 3, 5].includes(currentStep) ? "mouseover-hand" : "") + " phone-right"}
                        >
                            <StepIcon icon={<CustomIcon value={raumControllerEnabled ? 5 : 4}
                                                        active={currentStep === (raumControllerEnabled ? 5 : 4)}
                                                        completed={currentStep > (raumControllerEnabled ? 5 : 4)}
                                                        txt={project.projectType === 'konventionell' ? t('app.steps.four.konventionell') : t('app.steps.four')} />}>

                            </StepIcon>
                        </Step>
                        <Step key={raumControllerEnabled ? 6 : 5}
                              onClick={e => currentStep === (raumControllerEnabled ? 5 : 4) ? navigateToStep(raumControllerEnabled ? 6 : 5) : null}
                              className={(currentStep === (raumControllerEnabled ? 5 : 4) ? "mouseover-hand" : "") + " phone-right"}
                        >
                            <StepIcon icon={<CustomIcon value={raumControllerEnabled ? 6 : 5}
                                                        active={currentStep === (raumControllerEnabled ? 6 : 5)}
                                                        completed={currentStep > (raumControllerEnabled ? 6 : 5)}
                                                        txt={t('app.steps.five')} />}>

                            </StepIcon>
                        </Step>
                    </Stepper>
                </div>
            </div>
            {renderSwitch(currentStep)}
            {currentStep === (raumControllerEnabled ? 6 : 5) ? <>
                <div className={"navButtons" + (isIFrame ? ' isiframe' : '')} style={{maxWidth: "1116px", margin: "0 auto", paddingTop: '55px', paddingBottom: '55px', display: 'flex', alignItems: "center", position: 'relative', flexDirection: 'row'}}>
                    {isIFrame
                        ? <>
                            <ColorButton1 className={"grau"} style={{opacity: currentStep === 1 ? '0' : 'unset', pointerEvents: currentStep === 1 ? 'none' : 'unset'}} onClick={e => navigateToStep(raumControllerEnabled ? (currentStep === 5 ? 3.1 : (currentStep === 3.1 ? 3 : (currentStep - 1))) : (currentStep - 1))}>{t('app.button.back')}</ColorButton1>
                            <ColorButton2 style={{display: currentStep === (raumControllerEnabled ? 6 : 5) ? 'none' : 'flex', opacity: currentStep === (raumControllerEnabled ? 6 : 5) ? '0' : 'unset', pointerEvents: currentStep === (raumControllerEnabled ? 6 : 5) ? 'none' : 'unset'}} onClick={e => navigateToStep(raumControllerEnabled ? (currentStep === 3 ? 3.1 : (currentStep === 3.1 ? 5 : (currentStep + 1))) : (currentStep + 1))}>{t('app.button.next')}</ColorButton2>
                            <ColorButton1 className={"grau"} onClick={handleResetDialogOpen}>{t('app.menu.newProject')}</ColorButton1>
                        </>
                        : <>
                            {currentStep > 1 ?
                                <ColorButton1 className={"grau"} style={{marginLeft: 'auto', marginRight: 'auto'}}
                                              onClick={e => navigateToStep(raumControllerEnabled ? (currentStep === 5 ? 3.1 : (currentStep === 3.1 ? 3 : (currentStep - 1))) : (currentStep - 1))}>{t('app.button.back')}</ColorButton1> : null}
                            {currentStep < (raumControllerEnabled ? 6 : 5) ?
                                <ColorButton2 className={currentStep === 1 ? 'margin-auto' : ''}
                                              onClick={e => navigateToStep(raumControllerEnabled ? (currentStep === 3 ? 3.1 : (currentStep === 3.1 ? 5 : (currentStep + 1))) : (currentStep + 1))}>{t('app.button.next')}</ColorButton2> : null}
                            {currentStep < (raumControllerEnabled ? 6 : 5) ?
                                <ColorButtonToTop onClick={e => navigateToTop()} className="button woText">
                                    <img className="icon-to-page-bottom" src={uarr} alt={""}/>
                                    <img className="icon-to-page-bottom only-hover" src={uarrh} alt={""}/>
                                </ColorButtonToTop> : null}
                        </>
                    }
                </div>
            </>: null}
            <div className={"footer-container" + (isIFrame ? ' isiframe': '')}>
                {currentStep < (raumControllerEnabled ? 6 : 5)  ? <div className="navButtons">
                    {isIFrame
                        ? <>
                            <ColorButton1 className={"grau"} style={{opacity: currentStep === 1 ? '0' : 'unset', pointerEvents: currentStep === 1 ? 'none' : 'unset'}} onClick={e => navigateToStep(raumControllerEnabled ? (currentStep === 5 ? 3.1 : (currentStep === 3.1 ? 3 : (currentStep - 1))) : (currentStep - 1))}>{t('app.button.back')}</ColorButton1>
                            <ColorButton2 style={{opacity: currentStep === (raumControllerEnabled ? 6 : 5) ? '0' : 'unset', pointerEvents: currentStep === (raumControllerEnabled ? 6 : 5) ? 'none' : 'unset'}} onClick={e => navigateToStep(raumControllerEnabled ? (currentStep === 3 ? 3.1 : (currentStep === 3.1 ? 5 : (currentStep + 1))) : (currentStep + 1))}>{t('app.button.next')}</ColorButton2>
                            <ColorButton1 className={"grau"} onClick={handleResetDialogOpen}>{t('app.menu.newProject')}</ColorButton1>
                        </>
                        : <>
                            {currentStep > 1 ?
                                <ColorButton1 className={"grau"}
                                              onClick={e => navigateToStep(raumControllerEnabled ? (currentStep === 5 ? 3.1 : (currentStep === 3.1 ? 3 : (currentStep - 1))) : (currentStep - 1))}>{t('app.button.back')}</ColorButton1> : null}
                            {currentStep < (raumControllerEnabled ? 6 : 5) ?
                                <ColorButton2 className={currentStep === 1 ? 'margin-auto' : ''}
                                    onClick={e => navigateToStep(raumControllerEnabled ? (currentStep === 3 ? 3.1 : (currentStep === 3.1 ? 5 : (currentStep + 1))) : (currentStep + 1))}>{t('app.button.next')}</ColorButton2> : null}
                            {currentStep < (raumControllerEnabled ? 6 : 5) ?
                                <ColorButtonToTop onClick={e => navigateToTop()} className="button woText">
                                    <img className="icon-to-page-bottom" src={uarr} alt={""}/>
                                    <img className="icon-to-page-bottom only-hover" src={uarrh} alt={""}/>
                                </ColorButtonToTop> : null}
                        </>
                    }
                </div> : null}
                <div className={"footer" + (isIFrame ? ' hidden' : '')}>
                    <div className="footer-box">
                        {/*<div>*/}
                        {/*    Jung by FEELSMART. ©{new Date().getFullYear()}*/}
                        {/*</div>*/}
                        <div>
                            © Jung by FEELSMART.
                        </div>
                        <div className={"footerMenu"}>
                            <a href={"https://www.jung-group.com/de-DE/Weitere-Informationen/Impressum/"} target={"_blank"} rel="noreferrer">{t('app.menu.impressum')}</a>
                            <a href={"https://www.jung-group.com/de-DE/Weitere-Informationen/Datenschutzerklaerung/"} target={"_blank"} rel="noreferrer">{t('app.menu.dataProtection')}</a>
                            <a href={"https://feelsmart.com/agb"} target={"_blank"}
                               rel="noreferrer">{t('app.menu.userAgreement')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </MuiThemeProvider>
    }
    return <MuiThemeProvider theme={theme}>
        <div className="fs-container noBackground" style={{paddingTop: "0"}}>
            <div className="logoBox" style={{paddingBottom: "40px", paddingTop: "40px"}}>
                <img src={logoSvg} alt="logo" height="20px"/>
            </div>
        </div>
        <div className="fs-container noBackground" style={{paddingBottom: "0", paddingTop: "0", marginTop: "-119px"}}>
            {renderSwitch(currentStep)}
        </div>
        <div className="subpages">
            {renderStartSubPages()}
        </div>
        <div className="subpages">
            <div className="box subpage" style={{width: "100%"}}>
                <div className="page">
                    <div className="buttonsHolder bottom" style={{width: "100%"}}>
                        <ColorButton4 onClick={startProject}>
                            <span className={"headerMenuItem"}><strong>Konventionell</strong><br/>planen</span>
                        </ColorButton4>
                        <ColorButton4 onClick={startProject} style={{marginLeft: "1%"}}>
                            <span className={"headerMenuItem"}><strong>ETS-System</strong><br/>planen</span>
                        </ColorButton4>
                        <ColorButton4 onClick={startProject} style={{marginRight: "0", marginLeft: "1%"}}>
                            <span className={"headerMenuItem"}><strong>Easy-System</strong><br/>planen</span>
                        </ColorButton4>
                    </div>
                </div>
            </div>
        </div>
        <div className="fs-container noBackground">
            <div className={"footer fs-box-30 clearfix"}>
                {/*<div style={{float: "left"}}>*/}
                {/*    Jung by FEELSMART. ©{new Date().getFullYear()}*/}
                {/*</div>*/}
                <div style={{float: "left"}}>
                    © Jung by FEELSMART.
                </div>
                <div className={"footerMenu"} style={{float: "right"}}>
                    <a href={"https://www.jung-group.com/de-DE/Weitere-Informationen/Impressum/"} target={"_blank"} rel="noreferrer">Impressum </a>
                    <a href={"https://www.jung-group.com/de-DE/Weitere-Informationen/Datenschutzerklaerung/"} target={"_blank"} rel="noreferrer">Datenschutz</a>
                    <a href={"https://feelsmart.de/nutzungsbedingungen"} target={"_blank"}
                       rel="noreferrer">Nutzungsbedingungen</a>
                </div>
            </div>
        </div>
    </MuiThemeProvider>

}
